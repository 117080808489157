/* src/index.css */

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

/* Définition de l'animation pour le contenu */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Application de l'animation au contenu */
.content-animation {
  animation: fadeInUp 1s ease-out;
}
/* Ajoutez cette règle pour améliorer les performances de l'animation */
.content-animation {
  will-change: opacity, transform;
}

.title {
  font-family: 'Orbitron', sans-serif;
  font-weight: 700;
  /* Vous pouvez ajouter d'autres styles si nécessaire */
}

body {
  background: url('https://xrsalesdemo.blob.core.windows.net/xr-sales-images/dpbrud/assets/Wallpaper-small.jpg') no-repeat center center fixed; /* Ajoutez ici votre image de fond */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #ffffff;
  margin: 0;
  font-family: 'Orbitron', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Assure que le main prend toute la hauteur du viewport */
  padding: 20px;
}

.main-title {
  font-size: 3rem;
  color: #fc60d5;
  text-shadow: 0 0 15px #ff00c8, 0 0 30px #ff00c8, 0 0 45px #ff00c8;
  margin-bottom: 10px;
}

.sub-title {
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 30px;
  text-shadow: 0 0 5px #ff00c8, 0 0 10px #ff00c8;
}

.user-profile {
  background: rgba(0, 0, 0, 0.7);
  color: #ff00c8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px #ff00e1;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  backdrop-filter: blur(10px);
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-shadow: 0 0 10px #ff00c8, 0 0 20px #ff00c8, 0 0 30px #ff00c8;
}

.current-display-name {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 0 0 5px #ff00c8, 0 0 10px #ff00c8;
}

.input-field {
  background: rgba(255, 255, 255, 0.1);
  color: #faf9fa;
  padding: 10px;
  border: 1px solid #ff00c8;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px #ff00c8;
  width: 85%; /* Prend toute la largeur disponible dans .user-profile */
}

button {
  background-color: black;
  color: #ff00c8;
  border: 2px solid #ff00c8;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  text-shadow: 0 0 10px #ff00c8;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  width: 100%; /* Prend toute la largeur disponible dans .user-profile */
}

button:hover {
  background-color: #ff00c8;
  color: black;
  box-shadow: 0 0 20px #ff00c8;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  margin-top: 20px; /* Espace entre le formulaire et le logo */
  padding: 10px;
}

.footer-logo {
  width: 100px; /* Largeur du logo */
  height: auto; /* Conserve les proportions du logo */
}

.start-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
