/* src/Profile.css */

.profile-container {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 20px auto;
  color: #fff;
  text-align: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

p {
  margin-bottom: 20px;
  font-size: 1.2em;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
  font-size: 1em;
}

.error-message {
  background: #f44336; /* Red */
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}
